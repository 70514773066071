/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'

import Image from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import './styled.css'
import { Link } from 'gatsby'

const DesignSystemsHowdothis = () => {
  const DesignSystemsHowdothisImages = useStaticQuery(graphql`
      query DesignSystemsHowdothisImages {
        startingblock: file(absolutePath: {regex: "/assets/images/services/DesignSystems/BrandGuide.png/"}) {
          childImageSharp {
            sizes(maxWidth: 1280) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        tooling: file(absolutePath: {regex: "/assets/images/services/DesignSystems/DSMStack.png/"}) {
          childImageSharp {
            sizes(maxWidth: 1280) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        adoption: file(absolutePath: {regex: "/assets/images/services/DesignSystems/CreateAdoption.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1280) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    `)
    return (
      <Container className="howdo_this altgrid spt-10 border-bottom">
        <Row className="sp-5">
          <Col>
            <h2>What we help you do</h2>
          </Col>
        </Row>
        <Row className="sp-10 altrow">
          <Col md="6" lg="5">
            <h3 className="h2">Get out of the starting block fast</h3>
            <p>We help you get your design system program from 0 to 1 in weeks not months. Starting at 1.0 creates momentum and gives everyone something to contribute toward as we embark on the co-creation design system journey of your company.</p>
          </Col>
          <Col md="6" lg="6" className="offset-lg-1">
            <Image className="img-fluid" sizes={DesignSystemsHowdothisImages.startingblock.childImageSharp.sizes} alt="Get out of the starting block fast"/>
          </Col>
        </Row>
        <Row className="sp-10 altrow">
          <Col md="6" lg="6">
            <Image className="img-fluid" sizes={DesignSystemsHowdothisImages.tooling.childImageSharp.sizes} alt="We help you choose the right DSM and tooling"/>
          </Col>
          <Col md="6" lg="5" className="offset-lg-1">
            <h3 className="h2">We help you choose the right DSM and tooling</h3>
            <p>We are not tied to any one Design System Management tool, tech stack or framework. We’ve found the most successful implementations are not cookie cutter but rather fit for purpose. We help you choose the tools that fit your organisations need and not the other way around.</p>
          </Col>
        </Row>
        <Row className="sp-10 altrow">
          <Col md="6" lg="5">
            <h3 className="h2">Create adoption for your design system</h3>
            <p>We help you Improve cohesiveness and quicken speed-to-market with a design system that serves your teams.</p>
            <p>We coach you to transition into the modern organisation you are needing to become by blending ourselves alongside yours teams so they can deliver innovative and beautiful products, together as an organisation.</p>
          </Col>
          <Col md="6" lg="6" className="offset-lg-1">
            <Image className="img-fluid" sizes={DesignSystemsHowdothisImages.adoption.childImageSharp.sizes} alt="Create adoption for your design system"/>
          </Col>
        </Row>
      </Container>
    )
  }
export default DesignSystemsHowdothis

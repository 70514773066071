/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import './styled.css'

const DesignSystemsCasestudies = () => {
  const DesignSystemsCasestudiesImages = useStaticQuery(graphql`
      query DesignSystemsCasestudiesImages {
        spw: file(absolutePath: {regex: "/assets/images/SPWThumb.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1160) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        ffs: file(absolutePath: {regex: "/assets/images/FidoThumb.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1160) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    `)
    return (
      <div className="spb-10 Casestudies-s">
        <Container>
          <Row>
            <Col sm="12" lg="5" xl="4" className="titlef sp-5">
              <h2>Our design system work</h2>
            </Col>
           </Row>
           <Row>
              <Col sm="6">
                <div className="comingsoon">
                  <div className="project-img">
                    <Image className="img-fluid" sizes={DesignSystemsCasestudiesImages.spw.childImageSharp.sizes} alt="Sanlam Private Wealth"/>
                  </div>
                  <small class="clientname spw">Sanlam Private Wealth</small>
                  <h6 className="h3">
                    A design system led new brand home generates a 10x increase in business leads.
                  </h6>
                  <p className="technologies">
                    Product Management, Branding, UI/UX, Front-End Development, Back-End Development
                  </p>
                </div>
              </Col>
              <Col sm="6">
                <div className="comingsoon">
                  <div className="project-img">
                    <Image className="img-fluid" sizes={DesignSystemsCasestudiesImages.ffs.childImageSharp.sizes} alt="Frank Financial Services"/>
                  </div>
                  <small class="clientname ffs">Frank Financial Services</small>
                  <h6 className="h3">
                    Fun, quick and easy online credit reports get over 160,000 checks requested in the first 6 months. 
                  </h6>
                  <p className="technologies">
                    Product Management, Branding, UI/UX, Front-End Development, Back-End Development
                  </p>
                </div>
              </Col>
            </Row>
        </Container>
      </div>
    )
  }

export default DesignSystemsCasestudies
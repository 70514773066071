/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'gatsby'
import './styled.css'
import Image from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

const DesignSystemsTopimage = () => {
  const DesignSystemsTopimageImages = useStaticQuery(graphql`
      query DesignSystemsTopimageImages {
        top: file(absolutePath: {regex: "/assets/images/services/DesignSystems/MainBanner.png/"}) {
          childImageSharp {
            sizes(maxWidth: 2400) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    `)
    return (
      <Container className="scontent-wrap introduction">
        <Row className="sp-5">
          <div className="pb-5 col-12">
            <Link className="reversearrow" to="/services">
              View all services
              <svg
                className="styled__Arrow-d87459-0 bQLhSh"
                width="14"
                height="10"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g stroke="#030303" fill="none" fillRule="evenodd">
                  <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                  <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
                </g>
              </svg>
            </Link>
          </div>
          <Col lg="7">
            <h1>We help in-house teams make better digital products with design systems.</h1>
            <p>A design system is a tool to help your organisation build digital products more quickly and consistently. If you need help making one from scratch or evolving an existing one, we have the experience to help.</p>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <figure>
              <Image className="img-fluid" sizes={DesignSystemsTopimageImages.top.childImageSharp.sizes} alt="We help in-house teams make better digital products with design systems."/>
            </figure>
          </Col>
        </Row>
        <Row className="spb-10 border-bottom">
          <Col className="testimonial">
            <h2>Urbian’s implementation of our design system has thrust us into a future where we feel ahead of the digital curve and are no longer scared to launch new digital initiatives. Actually quite the opposite, we are now looking for opportunities.</h2>
            <span>
              Anel Grobler, Sanlam Private Wealth Marketing Manager
            </span>
          </Col>
        </Row>
      </Container>
    )
  }
export default DesignSystemsTopimage

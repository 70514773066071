/* eslint-disable */
import React from 'react'
import Layout from '../../components/Layout'
import {
  Topimage,
  Whyurbian,
  Howdothis,
  Headinfo,
  Contactus,
  Casestudies,
} from '../../components/designsystems'
import Gettouch from '../../components/Gettouch'
import { Helmet } from 'react-helmet'

class designsystems extends React.Component {
  
  render() {
    return (
      <div className="design-systems body greybackground">
        <Layout>
          <Helmet>
            <title>Urbian | Design systems</title>
          </Helmet>
          <Topimage></Topimage>
          <Howdothis></Howdothis>
          <Whyurbian></Whyurbian>
          <Casestudies></Casestudies>
          <Contactus location={this.props.location.pathname}/>
          <Headinfo></Headinfo>
          <Gettouch></Gettouch>
        </Layout>
      </div>
    )
  }
}

export default designsystems

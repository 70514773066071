/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'gatsby'

import './styled.css'
class Whyurbian extends React.Component {
  render() {
    return (
      <Container className="spb-10">
        <Row>
          <Col lg="5">
            <h2>Where to start?</h2>
          </Col>
          <Col lg="6">
            <p>A <b>12-week project</b>, where we’ll assess your whole brand across every experience while implementing a 1st phase of your Design System based on your current Brand CI style guides. Then we’ll explore some initial routes of the best way to go about implementing your Design System across your company and partners.</p>
            <Link className="iUBhrC" to="/contact">
              Get in touch
              <svg
                className="styled__Arrow-fdeFVt fRPwvC"
                width="14"
                height="10"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g stroke="#030303" fill="none" fillRule="evenodd">
                  <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                  <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
                </g>
              </svg>
            </Link>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default Whyurbian

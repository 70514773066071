/* eslint-disable */
import React, {useState} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { Link } from 'gatsby'
import Form from 'react-bootstrap/Form'
import axios from "axios";
import { navigate } from 'gatsby'


  const Forms = () => {
    const [isGoing, setIsGoing] = useState(true)
    const handleInputChange = (event) => {
      if(isGoing === true) {
        setIsGoing(false);
        setInputs(prev => ({
          ...prev,
          communication: false
        }));
      } else {
        setIsGoing(true);
        setInputs(prev => ({
          ...prev,
          communication: true
        }));
      }
    };
    /* NEW: Input state handling vvvv */
    const [inputs, setInputs] = useState({
      email: "",
      companyName: "",
      nameClient: "",
      mobile:"",
      communication:"",
      file:null,
    });
    const onChangeHandler = event =>{
      event.persist();
      setInputs(prev => ({
        ...prev,
        file: event.target.files[0]
      }));
    }
    const handleOnChange = event => {
      event.persist();
      setInputs(prev => ({
        ...prev,
        [event.target.id]: event.target.value
      }));
    };
    /* End input state handling ^^^^ */

    // Server state handling
    const [serverState, setServerState] = useState({
      submitting: false,
      status: null
    });
    const handleServerResponse = (ok, msg) => {
      setServerState({
        submitting: false,
        status: { ok, msg }
      });
      if (ok) {
        setInputs({
          email: "",
          companyName: "",
          nameClient: "",
          mobile:"",
          file:"",
          communication:"",
        });
      }
    };
    const handleOnSubmit = event => {
      event.preventDefault();
      setServerState({ submitting: true });
      const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
      }

      axios({
        method: "POST",
        url: "https://formspree.io/f/xvovzgyl",
        data: inputs,
        config
      })
        .then(r => {
          handleServerResponse(true, "Thanks!");
          navigate('/thank-you');
        })
        .catch(r => {
          handleServerResponse(false, r.response.data.error);
        });
    };

    return (

            <Form
              className="mt-5"
              name="Services Form"
              method="post"
              onSubmit={handleOnSubmit}
              encType="multipart/form-data"
            >

              <Form.Group >
                <Form.Label>Your company or project name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Company or project name"
                  name="companyName"
                  id="companyName"
                  onChange={handleOnChange}
                  value={inputs.companyName}
                  required
                />
              </Form.Group>
              <Form.Group >
                <Form.Label>Your name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name and surname pretty please"
                  name="nameClient"
                  id="nameClient"
                  onChange={handleOnChange}
                  value={inputs.nameClient}
                  required
                />
              </Form.Group>
              <Form.Group >
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Your company email address"
                  name="email"
                  id="email"
                  onChange={handleOnChange}
                  value={inputs.email}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Phone number</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Mobile number if possible please"
                    name="mobile"
                    value={inputs.mobile}
                    onChange={handleOnChange}
                    id="mobile"
                />
              </Form.Group>
              <div className="mt-4 footer-meeting-row-align checkbox-d ">
                {['checkbox'].map(type => (
                  <div key={`custom-${type}`} className="comms">
                    <Form.Check
                      checked={isGoing}
                      onChange={handleInputChange}
                      className="form-check-input"
                      name="communication"
                      type={type}
                      id={`ongoingcommuniation-${type}`}
                      label={`I want to receive ongoing communication `}
                    />
                  </div>
                ))}
                <button type="submit" className="styled__Button-ksqKNN iyLmoo styled__Button-kGpwPX dQtCFI form-contact-button">
                  Send
                  <svg
                    className="Arrow__Svg-gllSXh SARKW styled__Arrow-ihTfeJ cFLifL"
                    width="14"
                    height="10"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g stroke="#030303" fill="none" fillRule="evenodd">
                      <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                      <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
                    </g>
                  </svg>
                </button>
              </div>
              <small>
                  We share news, invitations to our events, blog posts and other exclusive content with subscribers. <a title="View our Privacy Policy" target="_blank" rel="noopener noreferrer" href="https://www.iubenda.com/privacy-policy/20841623">View our Privacy Policy</a> to find out more about how we take care of your personal data, how to update personal preferences or to unsubscribe.
              </small>
            </Form>

    )
  }


export default Forms
